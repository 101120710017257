import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import Img from 'gatsby-image'
import riskutbildning2 from '../../images/utbildningar/riskutbildning2.jpg'
const riskutbildningarna = (props) => (
  <Layout>
    <Seo title='Riskutbildningar' />
    <PageHeader text='Riskutbildningar' />
    <div className='container'>
      <div className='row justify-content-around'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3'>

          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
                <h2>Riskutbildning del 1</h2>
                <p>
                  Del 1 behandlar frågor kring alkohol, droger, trötthet och riskbeteende.
                  Riskutbildning del 1 är en teoretisk utbildning som du kan göra här hos oss på trafikskolan, den pågår i 180 min exlusive paus.
                  Vi har utbildningen oftast på tisdagar med start kl: 18:00
                </p>

                <h6>Riskutbildning 1 tar upp:</h6>
                <ul>
                  <li className='mb-0'>Hur du ska bli en säker, ansvarsfull förare.</li>
                  <li className='mb-0'>Konsekvenserna i trafiken.</li>
                  <li className='mb-0'>Medvetenhet om risker och farliga beteenden i trafiken. Du lär dig hur man på ett säkert sätt kan förutse och hantera dessa situationer.</li>
                  <li className='mb-0'>Risker och risktagande.</li>
                  <li className='mb-0'>Regler för rattfylleri och trötthet i trafiken.</li>
                  <li className='mb-0'>Olika gruppers olycksrisk såsom män, kvinnor, yngre och äldre.</li>
                  <li className='mb-0'>Hur passagerare, mobiltelefonanvändning, stress och mycket annat påverkar dig som förare.</li>
                </ul>
                {/* <p className='mb-1 text-sm-center'>
                  <Link to='/e-handel/productDetails/5473' className='btn btn-danger'> Boka riskutbildning del 1 här</Link>
                </p> */}
              </div>

              <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 mb-5 text-center'>
                {/* <img src={require('../../images/utbildningar/riskutbildning1.jpg')} alt='ansök' className='img-fluid rounded' /> */}
                <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='riskutbildning del 1' className='rounded mb-3' />
                <p className='mb-1 text-sm-center'>
                  {/* <Link to='/e-handel/productDetails/5473' className='btn btn-danger'> Boka riskutbildning del 1 här</Link> */}
                  <Link to='/boka-utbildning' className='btn btn-danger'> Boka riskutbildning del 1 här</Link>
                </p>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row mt-sm-1 mt-md-5 mt-lg-5 mt-xl-5 justify-content-between'>
              <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 text-center imageShowBigScreen'>
                {/* <img src={require('../../images/utbildningar/riskutbildning2.jpg')} alt='ansök' className='img-fluid rounded' /> */}
                <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt='riskutbildning del 2' className='rounded mb-3' />
                {/* <p className='mb-1 text-sm-center'>
                  <Link to='/e-handel/productDetails/5474' className='btn btn-danger'> Boka riskutbildning del 2 här</Link>
                </p> */}
              </div>

              <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
                <h2>Riskutbildning del 2</h2>
                <p>
                  Del 2 handlar om hastighet, säkerhet och körning under särskilda förhållanden.
                  Kursen är mestadels praktik och omfattar fyra timmar.
                  Riskutbildning del 2 gör du i slutet av personbilsutbildningen.
                  Det beror på att det ställs höga krav när det gäller bland annat bedömning, värdering och förståelse av olika trafiksituationer.
                  Dessutom måste du kunna manövrera ditt fordon på ett säkert sätt.
                  Vi använder oss av Gillingebanan som ligger vid E18 (Norrtäljevägen) ca 15 km från Danderyd.
                </p>

                <h6>Riskutbildning 2 tar upp:</h6>
                <li className='mb-0'>Risker och risktagande.  </li>
                <li className='mb-0'>Konsekvenser av risker och risktagande.  </li>
                <li className='mb-0'>Undvikande, att själv undvika risker och att förhindra andra.  </li>
                <li className='mb-0'>Hur beteenden och attityder påverkar trafiken.  </li>
                <li className='mb-0'>För personbil är risktvåan även känd som ”halkbana”, då körning under särskilda förhållanden körs på halt väglag.  </li>
              </div>

              <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mb-1 imageHideBigScreen'>
                <img src={riskutbildning2} alt='ansök' className='img-fluid rounded' />
                {/* <p className='mb-1 text-sm-center'>
                  <Link to='/e-handel/productDetails/5474' className='btn btn-danger'> Boka riskutbildning del 2 här</Link>
                </p> */}
              </div>

            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 mt-5 '>

            <div>
              <h3>Riskutbildningarna är giltiga i 5år</h3>
              <p>
                Riskutbildningen kan enbart genomföras hos utbildare som är godkända av Transportstyrelsen.
                Båda riskutbildningarna måste vara genomförda och giltiga innan du gör ditt teoriprov och körprov hos Trafikverket.
              </p>
            </div>

            <div>
              <h3>Medtag giltig legitimation på samtliga riskutbildningar!</h3>
              <p>Vid sen avbokning eller utebliven närvaro från bokad kurs debiteras eleven det motsvarande beloppet på det bokade kurstillfället.<br />
                Har du övriga frågor kring våra riskutbildningar eller finns något annat du undrar över? Skicka det här så återkommer vi så fort vi får tid.
              </p>
            </div>

          </div>

        </div>
      </div>
    </div>
  </Layout>

)

export default riskutbildningarna

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "utbildningar/riskutbildning1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageTwo: file(relativePath: { eq: "utbildningar/riskutbildning2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

}
`
